window.DarkMode = {
  on: false,
  theme: 'light',

  init () {
    let on = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;

    if (window.localStorage.getItem('jz.darkMode')) {
      on = window.localStorage.getItem('jz.darkMode') === 'true' ? true : false;
    }

    this.on = on;
    this.theme = on ? 'dark' : 'light';

    if (window.matchMedia) {
      $(window.matchMedia('(prefers-color-scheme: dark)')).on('change', (e) => {
        this.on = e.matches ? true : false;
        this.theme = this.on ? 'dark' : 'light';
      });
    }
  },

  toggle () {
    this.on    = !this.on;
    this.theme = this.on ? 'dark' : 'light';

    window.localStorage.setItem('jz.darkMode', this.on);
  }
}
