import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    $(this.scope.element).on('click', () => {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    });

    $(window).scroll(() => {
      if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
          $(this.scope.element).fadeIn();
        } else {
          $(this.scope.element).fadeOut();
        }
    });
  }
}
