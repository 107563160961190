import { Controller } from "stimulus";
import 'fslightbox';

export default class extends Controller {
  connect() {
    // $('.gallery .gallery-images img').each(function () {
    //   const $this = $(this);
    //   $this.data('src', $this);
    //   $this.attr('src', $this.attr('src').replace('dpr-2.0', 'dpr-2.0,q-10,bl-30'));
    //   $this.addClass('lazyload');
    // });

    $('figure.image-style-align-left', this.scope.element).addClass('mr-3 my-0');
    $('figure.image-style-align-right', this.scope.element).addClass('ml-3 my-0');
    $('figure figcaption', this.scope.element).addClass('text-muted small bg-transparent');

    $('h2, h3, h4, p, ol, ul, blockquote', this.scope.element).addClass('w-100 w-sm-65 mx-auto');

    $('.gallery', this.scope.element).css({ 'border-top': '2px solid white' });
    $('.gallery .gallery-images', this.scope.element).addClass('d-flex');
    $('.gallery .gallery-images img', this.scope.element).css({ 'border-left': '2px solid white' });
    $('.gallery .gallery-images img:first', this.scope.element).css({ 'border-left': '0' });
    $('.gallery .gallery-images figure', this.scope.element).addClass('my-0').css({ 'display': 'initial' }); // fucking safari
    $('.gallery .gallery-caption', this.scope.element).addClass('text-center text-muted small py-1');

    $('.gallery').each(function () {
      const
        name = '_' + Math.random().toString(36).substr(2, 9),
        caption = $('.gallery-caption', this).text()
      ;

      $('.gallery-images img', this).each(function () {
        const
          $this  = $(this),
          anchor = $('<a>')
            .attr('href', $this.attr('src'))
            .attr('data-fslightbox', name)
            .attr('data-caption', caption)
        ;

        $this.parents('figure').append(anchor);
        anchor.append($this);
      });
    });

    window.refreshFsLightbox();
    
    this.oambed();
  }

  oambed () {
    const script = document.createElement('script');
    script.src = 'https://cdn.iframe.ly/embed.js?api_key=4d88a2f82992808e48f865';
    script.setAttribute('async', true);
    script.onload = () => {
      $('oembed[url]', this.scope.element).each(function () {
        iframely.load(this, $(this).attr('url'));
      });
    };
    document.head.appendChild(script);
  }
}
