import $toast from '../packs/toast.js';

window.Checkout = {
  useShippingAddress: false,
  product: null,

  init () {
    this.useShippingAddress = $('#order_use_shipping_address').prop("checked");
    this.Cart.init();
  },

  helpers: {
    priceTag (number) {
      return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(number / 100);
    }
  }
};

window.Checkout.Cart = {
  shipping: {
    tier: 1,
    tiers: []
  },
  total: {
    int: 0,
    str: ''
  },
  enable_coupon: false,
  coupon: '',

  init () {
    this.calculate();
    this.listen();
  },

  calculate () {
    const total = $('dl dd[data-price]')
      .each(function () {
        const price = $(this).attr('data-price');
        $('span', this).html(window.Checkout.helpers.priceTag(price));
      })
      .map(function () {
        return parseInt($(this).attr('data-price'));
      })
      .toArray()
      .reduce((pv, cv) => {
        return pv + cv;
      })
    ;
    this.total.init = total;
    this.total.str = window.Checkout.helpers.priceTag(total);
  },

  listen () {
    $('#order_billing_country, #order_shipping_country').on('changed.bs.select', (e) => {
      const id = $(e.target).attr('id');
      if (window.Checkout.useShippingAddress && /billing/.test(id)) {
        return;
      }

      this.selectShippingTier(`#${id}`);
    });
  },

  selectShippingTier (selector) {
    const code = $(selector).selectpicker('val');

    this.shipping.tier = 3;
    for (const tier in this.shipping.tiers) {
      if (this.shipping.tiers[tier].includes(code)) {
        this.shipping.tier = parseInt(tier) + 1;
      }
    }

    $('#shipping_tier').attr('data-price', window.Checkout.product.shipping[`tier${this.shipping.tier}`].price);
    this.calculate();
  }
};
