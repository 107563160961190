window.Product = {
  init () {
    window.Product.Price.init();
  },

  buy () {
    window.location.href = `${this.$refs.buyBtn.href}?pid=${this.Price.id}`;
  },
};

window.Product.Price = {
  id: '',
  total: 'a',
  el: null,
  
  init () {
    for (const dt of document.querySelectorAll('dl dt[data-price]')) {
      dt.innerHTML = this.priceTag(dt.dataset.price);
    }

    this.set();
  },

  set (i = 0) {
    if (this.el) {
      this.el.classList.remove('active');
    }

    this.el = window.Product.$refs[`price${i}`];
    this.el.classList.add('active');

    this.id    = this.el.dataset.id;
    document.querySelector('dl dt:first-child').dataset.price = this.el.dataset.price;
    document.querySelector('dl dt:first-child').innerHTML     = this.priceTag(this.el.dataset.price);

    this.calculate();
  },

  calculate () {
    const total = Array.from(document.querySelectorAll('dl dt[data-price]'))
      .map((el) => {
        return parseInt(el.dataset.price)
      })
      .reduce((pv, cv) => {
        return pv + cv;
      })
    ;

    this.total = this.priceTag(total);
  },

  priceTag (number) {
    return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(number / 100);
  }
}
