import { Controller } from "stimulus";

export default class extends Controller {
  connect () {
    if (navigator.userAgent.indexOf('gonative') == -1) {
     return;
    }

    document.addEventListener('turbolinks:before-visit', (e) => {
      e.preventDefault();
      window.location.href = e.data.url;
    });

    $('.gonative-enable').removeClass('d-none');
    $('.gonative-disable').removeClass('d-flex d-block d-inline').addClass('d-none');
    $('.gonative-no-border-top').removeClass('border-top');

    // document.addEventListener('turbolinks:load', () => {
    //   $('.gonative-enable').removeClass('d-none');
    //   $('.gonative-disable').removeClass('d-flex d-block d-inline').addClass('d-none');
    //   $('.gonative-no-border-top').removeClass('border-top');
    // });

    this.urls = [];

    // this.urls.push('gonative://statusbar/set?color=80ff0000');
    this.sidebar();
    this.send();
  }

  send () {
    const json = JSON.stringify({ urls: this.urls });
    window.location.href = 'gonative://nativebridge/multi?data=' + encodeURIComponent(json);
  }

  sidebar () {
    const profile = {
      "subLinks": [
        {
          "subLinks": [],
          "label": "Profil",
          "url": "https://www.jagdzeit.de/profil/aendern",
          "icon": "fa-user"
        },
        {
          "subLinks": [],
          "label": "Lesezeichen",
          "url": "https://www.jagdzeit.de/bookmarks",
          "icon": "fa-bookmark"
        },
        {
          "subLinks": [],
          "label": "Bestellungen",
          "url": "https://www.jagdzeit.de/profil/bestellungen",
          "icon": "fa-list-ul"
        },
        {
          "subLinks": [],
          "label": "Abonnements",
          "url": "https://www.jagdzeit.de/profil/abonnements",
          "icon": "fa-book"
        },
        {
          "subLinks": [],
          "label": "Ausloggen",
          "url": "https://www.jagdzeit.de/auth/users/sign_out",
          "icon": "fa-power-off"
        }
      ],
      "label": "Profil",
      "isGrouping": true,
      "icon": "fa-user"
    };

    const items = [
      {
        "subLinks": [],
        "label": "Startseite",
        "url": "https://www.jagdzeit.de/",
        "icon": "fa-home"
      },
      {
        "subLinks": [],
        "label": "Alle Ausgaben",
        "url": "https://www.jagdzeit.de/ausgaben",
        "icon": "fa-book"
      },
      {
        "subLinks": [],
        "label": "Login",
        "url": "https://www.jagdzeit.de/auth/users/sign_in",
        "icon": "fa-user"
      },
      {
        "subLinks": [],
        "label": "Leser werben",
        "url": "https://www.jagdzeit.de/werben",
        "icon": "fa-gift"
      },
      {
        "subLinks": [],
        "label": "Über uns",
        "url": "https://www.jagdzeit.de/ueber",
        "icon": "fa-users"
      },
      {
        "subLinks": [],
        "url": "https://www.jagdzeit.de/meldungen",
        "label": "Meldungen",
        "icon": "fa-bullhorn"
      },
      {
        "subLinks": [],
        "url": "https://mailchi.mp/jagdzeit/jagdzeit-newsletter-app",
        "label": "Newsletter",
        "icon": "fa-envelope"
      },
      {
        "subLinks": [],
        "label": "Hilfe",
        "url": "https://www.jagdzeit.de/hilfe",
        "icon": "fa-question"
      },
      {
        "subLinks": [],
        "label": "Kontakt",
        "url": "https://www.jagdzeit.de/kontakt",
        "icon": "fa-comment"
      },
      {
        "subLinks": [
          {
            "subLinks": [],
            "label": "Impressum",
            "url": "https://www.jagdzeit.de/impressum",
            "icon": "fa-check"
          },
          {
            "subLinks": [],
            "label": "AGB",
            "url": "https://www.jagdzeit.de/agb",
            "icon": "fa-gavel"
          },
          {
            "subLinks": [],
            "label": "Datenschutz",
            "url": "https://www.jagdzeit.de/datenschutz",
            "icon": "fa-lock"
          }
        ],
        "label": "Rechtliche Hinweise",
        "isGrouping": true,
        "icon": "fa-info-circle"
      }
    ];

    if (window.$Jagdzeit.user) {
      items[2] = profile;
    }

    this.urls.push('gonative://sidebar/setItems?items=' + encodeURIComponent(JSON.stringify(items)))
  }
}
