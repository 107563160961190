import { Controller } from 'stimulus'

export default class extends Controller {
  connect() {
    if (!window.localStorage.suppressNewsletter) {
      window.setTimeout(() => {
        $(this.scope.element).removeClass('d-none');
      }, 8000);
    }
  }

  close () {
    $(this.scope.element).addClass('d-none');
    window.localStorage.suppressNewsletter = true;
  }
}
