import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    $('figure.image-style-align-left', this.scope.element).addClass('mr-3 my-0');
    $('figure.image-style-align-right', this.scope.element).addClass('ml-3 my-0');
    $('figure figcaption', this.scope.element).addClass('text-muted small bg-transparent');
    $('a', this.scope.element).addClass('text-underline');
  }
}
