// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

window.$ = require('jquery');
window.jQuery = window.$;

require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");

import 'css/application.scss';

import 'bootstrap';
import 'bootstrap-select';
import "alpinejs";
import 'lazysizes';

document.addEventListener("turbolinks:load", () => {
  if (window.reviveAsync) {
    var key = Object.keys(reviveAsync)[0];
    reviveAsync[key].apply(reviveAsync[key].detect());
  }

  $('[data-toggle="popover"]').each(function (event) {
    $(this).popover({
      trigger: 'hover',
      container: $(this).parent()
    });
  });

  $('.toast').toast('show');

  (() => {
    window.dataLayer = window.dataLayer || []
    dataLayer.push({ event: 'pageview' });
  })();
});

import "controllers";
import "alpine/dark-mode.js";
import "alpine/product.js";
import "alpine/checkout.js";
